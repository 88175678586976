@tailwind base;
@tailwind components;
@tailwind utilities;

/* KYC */

@layer components {
    .kyc-text-field {
        @apply shadow-sm block w-full sm:text-sm rounded-md;
    }
    .kyc-field-rdonly {
        @apply flex font-mont items-center h-10 p-3 bg-hoverBg rounded font-normal text-sm;
    }

    .react-datepicker__input-container > input {
        @apply relative inline-block w-full p-2.5 font-mont sm:text-sm appearance-none border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-grayColor disabled:text-outlinedBtnBgDisabled kyc-text-field no-spin-button;
    }

    .onfocus-ring-blue {
        /* @apply focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 */
    }

    .onfocus-ring-error {
        @apply focus:ring-redErr;
    }
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .no-spin-button::-webkit-outer-spin-button,
    .no-spin-button::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    .no-spin-button {
        -moz-appearance: textfield;
    }
}
::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::-webkit-file-upload-button {
    cursor: pointer;
}

/* IE11 (if it doesn't work, try maybe also on the parent/wrapper) */
input[type='file'] {
    cursor: pointer;
}
